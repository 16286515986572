
export default interface ExceptionsState {
  requestStarted: boolean;
  requestSucceed: boolean;
  requestFailed: boolean;
  requestError?: string | null;
  requestErrorCode?: number;
  requestCreator?: string | null;
}

export const initialExceptionsState: ExceptionsState = {
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestError: null,
  requestCreator: null,
  requestErrorCode: 0
};
