import React, { useEffect, useMemo, useRef } from "react";
import Button, { ButtonThemes } from "../../../components/button/button";
import { ConsigneeDetails, ProbillDetails } from "../../../app/data/stop-details/models";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "../../../components/accordion/accordion";
import { AccordionRef } from "../../../components/accordion/types";
import ExceptionButton from "../../exceptions/exceptionButton";

interface ConsigneeAccordionItemProps {
  manifestNumber: number;
  stopNumber: number;
  consignee: ConsigneeDetails;
  children: any;
  onClickSign?: (consignee: ConsigneeDetails) => void;
  id: string;
  isStopCompleted: boolean;
}

const isAllProbillsRefused = (probills: ProbillDetails[])  => {
  let refusedProbills: ProbillDetails[] = [];
  probills.forEach(probill => {
    // Check if all items in the pro is refused, if yes add to list.
    if (probill.items === probill.itemDetails.filter(item => !!item.exception?.refused).length) {
      refusedProbills.push(probill)
    } else if (probill.exception?.refused) {
      refusedProbills.push(probill)
    }
  })
  return refusedProbills.length === probills.length;
}
export const ConsigneeAccordionItem: React.FC<ConsigneeAccordionItemProps> = ({
  manifestNumber,
  stopNumber,
  consignee,
  children,
  onClickSign,
  isStopCompleted
}) => {
  const accordionRef = useRef<AccordionRef>(null);

  const isTerminalException = useMemo(() => {
    return (consignee.exception?.reason === "BUSINESS_CLOSED" || consignee.exception?.reason === "CANNOT_DELIVER"  || consignee.exception?.reason === "CUSTOMER_REFUSED");
  }, [consignee]);

  const isConsigneeRefused = useMemo(() => {
    return isAllProbillsRefused(consignee.probills) || !!consignee.exception?.refused
  }, [consignee])

  const showException = () => {
    let fullOfExceptions = true;
    const probillsWithoutLevelException = consignee.probills.filter(probill => !probill.exception);
    for (const probill of probillsWithoutLevelException) {
      if (probill.itemDetails.find(item => !item.exception)) fullOfExceptions = false;
    }

    return consignee.probills &&
      (!(consignee.delivered || isStopCompleted) || consignee.exception) &&
      !fullOfExceptions;
  };

  useEffect(() => {
    if (consignee.delivered || isConsigneeRefused) accordionRef.current?.unCollapse();
  }, [consignee.delivered, isConsigneeRefused]);

  const itemsCount = consignee.probills.reduce((a, b) => a + b.items, 0);

  return (
    <AccordionItem
      className={`xgs-consignee-accordion ${
        (consignee.delivered || isConsigneeRefused) ? "xgs-consignee-accordion--delivered" : ""
      }`}
    >
      <AccordionItemButton
        ref={accordionRef}
        className={`xgs-consignee-accordion__button ${
          (consignee.delivered || isConsigneeRefused || isTerminalException)
            ? "xgs-consignee-accordion__button--delivered"
            : ""
        }`}
      >
        <div className="xgs-consignee-accordion__button__text">
          <div className="xgs-consignee-accordion__button__text__consignee">
            {consignee.name}
          </div>
          <div className="xgs-consignee-accordion__button__text__number">
            {consignee.probills.length} Probill
            {consignee.probills.length > 1 ? "s" : ""}, {itemsCount} Item
            {itemsCount > 1 ? "s" : ""}
            {isConsigneeRefused && <>,&nbsp;<span className="xgs-consignee-accordion__button__text__number__refused">Refused</span></>}
          </div>
        </div>
        <div className="xgs-consignee-accordion__button__actions">
          {showException() &&
            <ExceptionButton
              manifestNumber={manifestNumber}
              stopNumber={stopNumber}
              probills={consignee.probills.map(probill => probill.probill)}
              itemId={null}
              source="CONSIGNEE"
              exception={consignee.exception}
            />
          }
          {(!consignee.delivered && !isConsigneeRefused && !isTerminalException) && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                onClickSign && onClickSign(consignee);
              }}
              theme={ButtonThemes.green}
              className={`xgs-consignee-accordion__button__mark`}
            >
              Deliver
            </Button>
          )}
        </div>
      </AccordionItemButton>
      <AccordionItemPanel className="xgs-consignee-accordion__panel">
        {children}
      </AccordionItemPanel>
    </AccordionItem>
  );
}
