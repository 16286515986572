import { ApiBaseModel } from "../../app/data/common/models";
import { StopDetails } from "../../app/data/stop-details/models";

export default interface StopDetailsState
  extends ApiBaseModel {
    details: StopDetails[] | null;
}

export const initialStopDetailsState: StopDetailsState = {
  details: null,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null
}
