import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import { AccordionContext, AccordionIndexContext, AccordionItemProps, AccordionItemTitleProps, AccordionProps, AccordionRef } from "./types";
import "./accordion.scss";

const Accordion: React.FC<AccordionProps> = ({ children, id, openByDefault }) => {
  const initializeOpenSections = () => {
    const sections: { [key: string]: boolean } = {};
    React.Children.forEach(children, (_, idx) => {
      sections[idx] = openByDefault ?? false;
    });
    return sections;
  };
  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(initializeOpenSections());

  const toggleSection = (index: number) => {
    const isOpen = !!openSections[index];
    setOpenSections(prev => ({
      ...prev,
      [index]: !isOpen
    }));
  };
  
  const renderChildren = () => {
    return React.Children.map(children, (child, idx) => (
      <AccordionIndexContext.Provider value={idx}>
        {child}
      </AccordionIndexContext.Provider>
    ));
  }

  return (
    <AccordionContext.Provider value={{ openSections, toggleSection }}>
      <div id={id}>{renderChildren()}</div>
    </AccordionContext.Provider>
  );
};

const AccordionItem: React.FC<AccordionItemProps> = ({ children , className}) => {
  return <div className={className}>{children}</div>;
};


const AccordionButton: React.ForwardRefRenderFunction<AccordionRef, AccordionItemTitleProps> = (({ children, className, disabled }, ref) => {
  const { toggleSection, openSections } = useContext(AccordionContext)!;
  const idx = useContext(AccordionIndexContext);
  const unCollapse = useCallback(() => {
    if (openSections[idx]) {
      toggleSection(idx);
    }
  }, [openSections, idx, toggleSection])
  useImperativeHandle(ref, () => ({
    toggleSection: toggleSection.bind(this, idx),
    unCollapse
  }))

  useEffect(() => {
    if (disabled) {
      unCollapse()
    }
  }, [disabled, unCollapse])

  return (
    <div tabIndex={1} role="button" aria-expanded={openSections[idx]} onClick={() => !disabled && toggleSection(idx)} className={`xgs-accordion__button ${className}`}>
      {children}
    </div>
  );
});

const AccordionItemPanel: React.FC<any> = ({ children, className, index }) => {
  const { openSections } = useContext(AccordionContext)!;
  const idx = useContext(AccordionIndexContext)
  if (!openSections[idx]) return null;
  return <div className={className}>{children}</div>;
};

export const AccordionItemButton = forwardRef(AccordionButton);
export { Accordion, AccordionItem , AccordionItemPanel };
