export const dateFormats = {
  date: "MM-DD-YYYY",
  uiDate: "MM/DD/YYYY",
  uiTime: "hh:mm a",
  uiDateWeek: "ddd, MM/DD/YYYY",
  uiDateTime: "MM/DD/YYYY hh:mm:ss a",
  apiDate: "YYYY-MM-DD",
  apiDateTime: "YYYY-MM-DD hh:mm:ss a",
};

export default dateFormats;