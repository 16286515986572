import api, { ApiResponse } from "../api";

class RouteSummaryService {
  private static instance: RouteSummaryService;
  private constructor() {};

  static getInstance(): RouteSummaryService {
    if (!RouteSummaryService.instance) {
      RouteSummaryService.instance = new RouteSummaryService();
    }
    return RouteSummaryService.instance;
  };

  public getRouteSummary = async (
    manifestNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.get(`/drivers/routes/${manifestNumber}`);
  };

  public startRoute = async (
    manifestNumber: number,
    stopNumber: number
  ): Promise<ApiResponse<any>> => {
    return await api.post(`/drivers/routes/${manifestNumber}/stops/${stopNumber}/start`);
  };
}

export default RouteSummaryService;
