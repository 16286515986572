import { createSlice } from "@reduxjs/toolkit";
import StopDetailsService from "../../app/data/stop-details/stopDetailsService";
import { initialStopDetailsState } from "./StopDetailsState";
import { AppThunk } from "../../app/store";
import { IState } from "..";

const stopDetailsService = StopDetailsService.getInstance();

export const stopDetailsSlice = createSlice({
  name: "stopDetails",
  initialState: initialStopDetailsState,
  reducers: {
    resetStopDetails: (state) => state = initialStopDetailsState,
    requestStarted: (state) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setStopDetails: (state, { payload }) => {
      const stopsDetails = [...(state.details || [])];
      const i = payload.stopNumber - 1;
      stopsDetails[i] = payload.details;
      state.details = [...stopsDetails];
    },
    setStopsDetails: (state, { payload }) => {
      state.details = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setStopDetails,
  setStopsDetails,
  resetStopDetails
} = stopDetailsSlice.actions;

export const stopDetailsSelector = (state: IState) => state.stopDetails;

export const getAllStopsDetails = (
  manifestNumber: number
): AppThunk => async (dispatch) => {
  dispatch(requestStarted())
  const response = await stopDetailsService.getAllStopsDetails(manifestNumber);
  if (response.ok()) {
    dispatch(setStopsDetails(response.data));
    dispatch(requestSucceed());
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

const stopDetailsReducer = stopDetailsSlice.reducer;
export default stopDetailsReducer;
