import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../slices/user/userSlice";
import { commonSelector } from "../../slices/common/commonSlice";
import logo from "./../../images/logo.svg";
import { publicRoutesComponents } from "../../app/route/RoutesConfig";
import UserMenu from "./user-menu/userMenu";
import OfflineNotifier from "./offline-notifier/offlineNotifier";
import "./header.scss";

const Header: React.FC<{}> = (props) => {
  const userState = useSelector(userSelector);
  const commonState = useSelector(commonSelector);
  const location = useLocation();

  const isPublicPage = () => {
    return publicRoutesComponents.find((route) => {
      return route.fixedPath && location.pathname.startsWith(route.fixedPath + '/');
    });
  };

  return (
    <div className="xgs-header">
      <div className="xgs-header__start">
        <OfflineNotifier />
        {userState.profile?.badgeNumber && (
          <div className={`xgs-badge-number${(commonState.offline || !navigator.onLine) ? " xgs-badge-number--offline" : ""}`}>
            <div className="xgs-badge-number__value">{userState.profile?.badgeNumber}</div>
            <div className="xgs-badge-number__label">Badge #{(commonState.offline || !navigator.onLine) ? ":" : ""}</div>
          </div>
        )}
      </div>
      <div className="xgs-header__middle">
        <div className="xgs-header__logo">
          <NavLink to="/">
            <img className="xgs-logo" src={logo} alt="xgsi.com logo" />
          </NavLink>
        </div>
      </div>
      <div className="xgs-header__end">
        {userState?.loggedIn && !isPublicPage() && <UserMenu />}
      </div>
    </div>
  );
};

export default Header;
