import React from "react";
import { useField } from "formik";
import "./xgsFormRadioGroup.scss";

interface XGSFormRadioGroupItemModel {
  label: string;
  value: string | boolean;
}

interface XGSFormRadioGroupProps {
  items: XGSFormRadioGroupItemModel[];
  name: string;
  onValueChange?: (value: string| boolean) => void;
  disabled?: boolean;
}

const XGSFormRadioGroup: React.FC<XGSFormRadioGroupProps> = ({ items, name, onValueChange, disabled }) => {
  const [field, , helpers] = useField(name);
  const currentValue = field.value;

  const onItemClick = (optionValue: string | boolean) => {
    helpers.setValue(optionValue);
    onValueChange && onValueChange(optionValue);
  };

  return (
    <div className="xgs-radio__group">
      {items.map((item, i) => (
        <div
          key={`radio-${i}`}
          role="radio"
          tabIndex={i}
          aria-checked={currentValue === item.value}
          className={`xgs-radio__group__item${currentValue === item.value ? " xgs-radio__group__item--active" : ""}`}
          onClick={() => !disabled && onItemClick(item.value)}
        >
          <label className="xgs-radio__group__item__label">{item.label}</label>
        </div>
      ))}
    </div>
  );
};

export default XGSFormRadioGroup;
