
export const enum ManifestStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
  PENDING = "PENDING"
};

export const enum StopStatus {
  COMPLETED = "COMPLETED",
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING"
};
