import { ApiBaseModel } from "../../app/data/common/models";
import {
  SubmitPodRequest,
} from "../../app/data/stop-details/models";

export interface DeliverShipmentState extends ApiBaseModel {
  pods: SubmitPodRequest[];
}

export const initialDeliverShipmentState: DeliverShipmentState = {
  pods: [],
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  requestFailReason: null,
  requester: null
};
