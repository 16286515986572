import React, { useEffect, useState, useRef } from "react";
import "./map.scss";

export interface XgsMapProps extends google.maps.MapOptions {
  center: {
    lat: number,
    lng: number
  };
  children?: React.ReactNode;
}

const XGSMap: React.FC<XgsMapProps> = ({ center, children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  const mapId = () => Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {
        center,
        zoom: 12,
        mapId: `xgs-map-${mapId}`
      }));
    }
  }, [ref, map, center]);

  useEffect(() => {
    if (map) {
      map.setOptions(props);
    }
  }, [map, props]);

  return (
    <>
      <div ref={ref} className="xgs-map" />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

export default XGSMap;
