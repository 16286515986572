import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import "./loading.scss";

export interface LoadingProps {
  className?: string;
  isLoading?: boolean;
  isSmall?: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <>
      {props.isLoading && (
        <>
          {!props.className && (
            <FontAwesomeIcon
              icon={Icons.faSpinner}
              size={props.isSmall ? "lg" : "3x"}
              spin={true}
              className={`xgs-loading ${props.className ? props.className : ""}`}
            />
          )}
          {props.className && (
            <div className={props.className || ""}>
              <FontAwesomeIcon
                icon={Icons.faSpinner}
                size={props.isSmall ? "lg" : "3x"}
                spin={true}
                className="xgs-loading"
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Loading;