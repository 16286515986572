import React from "react";
import { RollDetails } from "../roll-details/rollDetails";
import { ProbillDetails } from "../../../app/data/stop-details/models";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "../../../components/accordion/accordion";
import ExceptionButton from "../../exceptions/exceptionButton";

export interface ProbillAccordionItemProps {
  manifestNumber: number;
  stopNumber: number;
  probill: ProbillDetails;
  idx: number;
  consigneeHasException?: boolean;
  consigneeDelivered?: boolean;
  isStopCompleted?: boolean;
  consigneeIsRefused?: boolean;
  isPickup?: boolean;
}

export const ProbillAccordionItem: React.FC<ProbillAccordionItemProps> = ({
  manifestNumber,
  stopNumber,
  probill,
  consigneeHasException,
  consigneeDelivered,
  consigneeIsRefused,
  isStopCompleted,
  isPickup
}) => {

  const showException = () => {
    return probill.probill &&
      !(consigneeHasException && !probill.exception) &&
      (!(consigneeDelivered || isStopCompleted) || probill.exception) &&
      probill.itemDetails.find(item => !item.exception) &&
      !isPickup;
  };

  return (
    <AccordionItem className={`xgs-probill-accordion`}>
      <AccordionItemButton className={`xgs-probill-accordion__button`}>
        {isPickup ? `Pickup: ${probill.probill}` : `Probill: ${probill.probill}`}
        {showException() &&
          <ExceptionButton
            manifestNumber={manifestNumber}
            stopNumber={stopNumber}
            probills={[probill.probill]}
            itemId={null}
            source="PROBILL"
            exception={probill.exception}
          />
        }
      </AccordionItemButton>
      <AccordionItemPanel className="xgs-probill-accordion__panel">
        <RollDetails
          manifestNumber={manifestNumber}
          stopNumber={stopNumber}
          rollDetails={probill.itemDetails}
          probillHasException={!!probill.exception || consigneeHasException}
          probillDelivered={consigneeDelivered}
          probillIsRefused={probill.exception?.refused || consigneeIsRefused}
          isStopCompleted={isStopCompleted}
          isPickup={isPickup}
        />
      </AccordionItemPanel>
    </AccordionItem>
  );
};
