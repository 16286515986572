import React, { memo } from "react";
import { FieldInputProps, FieldMetaProps } from "formik";
import "./labeledInput.scss";

export enum LabelModes {
  column = "column",
  row = "row",
}

export interface CommonLabeledInputElementProps {
  fields?: FieldInputProps<any>;
  meta?: FieldMetaProps<any>;
  error?: string;
}

export interface CommonLabeledInputProps {
  label?: string;
  required?: boolean;
  labelMode?: LabelModes;
  requiredAsteriskDisabled?: boolean;
}

export interface LabeledInputProps
  extends CommonLabeledInputProps,
    CommonLabeledInputProps,
    React.HTMLProps<HTMLInputElement> {
  isFailed?: () => boolean;
  error?: string;
}

const LabeledInput: React.FC<LabeledInputProps> = memo((props) => {
  return (
    <div
      className={`xgs-labeled-input ${props.labelMode} ${props.className} ${
        props.isFailed && props.isFailed() ? "xgs-labeled-input-failed" : ""
      }`}
    >
      {props.label && (
        <label>
          {props.label}{" "}
          {props.required && !props.requiredAsteriskDisabled && <span>*</span>}
        </label>
      )}
      {props.children}
      {props.isFailed && props.isFailed() && (
        <span className="xgs-labeled-input__validation-error">
          {props.error}
        </span>
      )}
    </div>
  );
});

export default LabeledInput;
