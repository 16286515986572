import React from "react";
import { useSelector } from "react-redux";
import UserState from "../../slices/user/UserState";
import { userSelector } from "../../slices/user/userSlice";
import "./routeSelect.scss";

interface NoRouteProps {
  pickupsAssigned: boolean;
};

const NoRoute: React.FC<NoRouteProps> = (props) => {
  const userState: UserState = useSelector(userSelector);

  return (
    <div className={`xgs-route-selector__no-route ${props.pickupsAssigned ? "xgs-route-selector__no-route--w-pickups" : ""}`}>
      <div className="xgs-route-selector__no-route__header">
        {userState.profile?.firstName ? `${userState.profile.firstName}, you ` : "You "} have not been assigned a route.
      </div>
      <div className="xgs-route-selector__no-route__contact">
        <div className="xgs-route-selector__no-route__contact__header">
          Please contact your dispatch office.
        </div>
      </div>
    </div>
  );
}

export default NoRoute;
