import api, { ApiResponse } from "../api";
import { ManifestModel } from "./models";

class RouteSelectService {
  private static instance: RouteSelectService;
  private constructor() {}
  static getInstance(): RouteSelectService {
    if (!RouteSelectService.instance) {
      RouteSelectService.instance = new RouteSelectService();
    }
    return RouteSelectService.instance;
  }

  public getManifests = async (): Promise<ApiResponse<ManifestModel[]>> => {
    return await api.get("/drivers/routes");
  };
}

export default RouteSelectService;