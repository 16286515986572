import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { initialCommonState } from "./CommonState";

export const commonSlice = createSlice({
  name: "common",
  initialState: initialCommonState,
  reducers: {
    resetCommonState: (state) => initialCommonState,
    setOffline: (state, { payload }) => {
      state.offline = payload;
    }
  }
});

export const {
  resetCommonState,
  setOffline
} = commonSlice.actions;

export const commonSelector = (state: IState) => {
  return state.common;
};

const commonReducer = commonSlice.reducer;
export default commonReducer;
