import { createContext, ReactNode } from 'react'; 

export type AccordionContextType = {
  openSections: { [key: number]: boolean };
  toggleSection: (index: number) => void;
};

export const AccordionIndexContext = createContext<number>(-1);

export const AccordionContext = createContext<AccordionContextType | undefined>(undefined);

export type AccordionProps = {
  children: ReactNode;
  className?: string;
  id?: string;
  openByDefault?: boolean;
};

export type AccordionItemProps = {
  children: ReactNode;
  className?: string;
};

export type AccordionRef = {
  toggleSection: () => void;
  unCollapse: () => void;
};

export type AccordionItemTitleProps = {
  className: string;
  children: ReactNode;
  disabled?: boolean;
  ref?: React.RefObject<AccordionRef>;
};

export type AccordionItemBodyProps = {
  className: string;
  children: ReactNode;
};
