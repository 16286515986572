import { RouteMapModel } from "../../app/data/route-map/models";
import { ApiBaseModel } from "../../app/data/common/models";

export default interface RouteMapState
  extends ApiBaseModel {
  mapData: RouteMapModel | null;
}

export const initialRouteMapState: RouteMapState = {
  mapData: null,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null
};
