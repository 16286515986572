import { ApiBaseModel } from "../../app/data/common/models";
import { PickupModel } from "../../app/data/pickups/models";

export default interface PickupsState
  extends ApiBaseModel {
  pickups: PickupModel[];
}

export const initialPickupsState: PickupsState = {
  pickups: [],
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null
};
