import React from "react";
import { toast } from "react-toastify";
import XGSIcon from "../../components/icon/xgsIcon";
import XGSIcons from "../../components/icon/xgsIcons";
import "./addressMapButton.scss";

interface AddressMapButtonProps {
  lat: number;
  lng: number;
}

const AddressMapButton: React.FC<AddressMapButtonProps> = (props) => {
  const onButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    let newTab = window.open(`geo:${props.lat},${props.lng}?q=${props.lat},${props.lng}`, "_blank");
    if (newTab === null || typeof(newTab) === "undefined") {
      // if new window was blocked by a system
      toast.error("You must turn off the pop-up blocking in your browser to create a route.", {
        autoClose: 7000
      });
    }
  };

  return (
    <>
      {(props.lat && props.lng) ? (
        <div
          className="xgs-address-map-button"
          onClick={onButtonClick}
        >
          <div className="xgs-address-map-button__icon">
            <XGSIcon
              icon={XGSIcons.faRoute}
            />
          </div>
          <div className="xgs-address-map-button__text">
            route
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AddressMapButton;
