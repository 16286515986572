import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import RouteSummaryService from "../../app/data/route-summary/routeSummaryService";
import { initialRouteSummaryState } from "./RouteSummaryState";

const routeSummaryService = RouteSummaryService.getInstance();

export const routeSummarySlice = createSlice({
  name: "routeSummary",
  initialState: initialRouteSummaryState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setRouteSummary: (state, { payload }) => {
      state.route = payload;
    },
    updateRouteStatus: (state, { payload }) => {
      if (!state.route) return;
      state.route.status = payload;
    },
    updateStopStatus: (state, { payload }) => {
      if (!state.route || !state.route.stops) return;
      const i = state.route.stops.findIndex((stop) => stop.order === payload.order);
      if (i === -1) return;
      state.route.stops[i].status = payload.status;
      //const newData = state.route;
      //state.route = newData;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setRouteSummary,
  updateRouteStatus,
  updateStopStatus
} = routeSummarySlice.actions;

export const routeSummarySelector = (state: IState) => state.routeSummary;

export const getRouteSummary = (
  manifestNumber: number,
  onSuccess?: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUMMARY"));
  const response = await routeSummaryService.getRouteSummary(manifestNumber);
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setRouteSummary(response.data));
    onSuccess && onSuccess();
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

export const startRoute = (
  manifestNumber: number,
  stopNumber: number,
  onSuccess: () => void,
  onOffline: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("START_ROUTE"));
  const response = await routeSummaryService.startRoute(manifestNumber, stopNumber);
  if (navigator.onLine) {
    if (response.ok()) {
      dispatch(requestSucceed());
      onSuccess();
    } else {
      dispatch(requestFailed(response.getError && response.getError()));
    }
  } else {
    dispatch(requestSucceed());
    onSuccess();
    onOffline();
  }
};

const routeSummaryReducer = routeSummarySlice.reducer;
export default routeSummaryReducer;
