import { ManifestModel } from "../../app/data/route-select/models";
import { ApiBaseModel } from "../../app/data/common/models";

export default interface RouteSelectState
  extends ApiBaseModel {
  manifests: ManifestModel[];
  activeManifest: number | null;
  selectedManifest: number | null;
  routeSelectCalledFromMenu: boolean;
}

export const initialRouteSelectState: RouteSelectState = {
  manifests: [],
  activeManifest: null,
  selectedManifest: null,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null,
  routeSelectCalledFromMenu: false
};
