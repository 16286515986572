import api, { ApiResponse } from "../api";

class PickupShipmentService {
  private static instance: PickupShipmentService;
  private constructor() {};

  static getInstance(): PickupShipmentService {
    if (!PickupShipmentService.instance) {
      PickupShipmentService.instance = new PickupShipmentService();
    }
    return PickupShipmentService.instance;
  };

  public completePickup = async (fd: FormData): Promise<ApiResponse<any>> => {
    return await api.post(`/drivers/routes/pickup`, fd);
  }
}

export default PickupShipmentService;
