import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import PickupsService from "../../app/data/pickups/pickupsService";
import { initialPickupsState } from "./PickupsState";

const pickupsService = PickupsService.getInstance();

export const pickupsSlice = createSlice({
  name: "pickups",
  initialState: initialPickupsState,
  reducers: {
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
      state.requester = payload;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setPickups: (state, { payload }) => {
      state.pickups = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setPickups
} = pickupsSlice.actions;

export const pickupsSelector = (state: IState) => state.pickups;

export const getPickups = (
  onFailed?: (httpStatusCode: number) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("GET_PICKUPS"));
  const response = await pickupsService.getPickups();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setPickups(response.data));
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
    onFailed && onFailed(response.status);
  }
};

const pickupsReducer = pickupsSlice.reducer;
export default pickupsReducer;