import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface ExceptionModel {
  comment: string;
  id: string;
  itemId: string;
  level: string;
  manifest: number;
  photosCount: number;
  probills: number[];
  reason: string;
  refused: boolean;
  terminalException: boolean;
}

export interface ShortExceptionModel {
  id: string;
  probills: number[];
  level: string;
}

export interface ExceptionFormModel {
  reason: string;
  refused: boolean | undefined;
  comment?: string;
}

export const ExceptionSchema: Yup.ObjectSchema<ExceptionFormModel> = Yup.object({
  reason: Yup.string().required(validationMessages.required),
  refused: Yup.boolean().required(validationMessages.required),
  comment: Yup.string().max(60, "The value must be no more than 60 characters")
}).defined();
