import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks/storeHooks";
import { ROUTES } from "../../app/route/RoutesConfig";
import {
  routeSelectSelector,
  setRouteSelectCalledFromMenu
} from "../../slices/route-select/routeSelectSlice";
import { pickupsSelector } from "../../slices/pickups/pickupsSlice";
import "./menu.scss";

const Menu: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const routeSelectState = useSelector(routeSelectSelector);
  const pickupsState = useSelector(pickupsSelector);

  return (
    <div className="xgs-menu">
      {(routeSelectState.manifests.length > 1) && (
        <div
          className={`xgs-menu__item${location.pathname === ROUTES.manifests ? " xgs-menu__item--active" : ""}`}
          onClick={() => {
            dispatch(setRouteSelectCalledFromMenu(true));
            navigate(ROUTES.manifests);
          }}
        >
          Manifests
        </div>
      )}
      {(routeSelectState.manifests.length === 0) && (pickupsState.pickups.length > 0) && (
        <div
          className={`xgs-menu__item${location.pathname === ROUTES.manifests ? " xgs-menu__item--active" : ""}`}
          onClick={() => {
            dispatch(setRouteSelectCalledFromMenu(true));
            navigate(ROUTES.manifests);
          }}
        >
          Pickups
        </div>
      )}
      {(routeSelectState.manifests.length > 0) && (
        <>
          <div
            className={`xgs-menu__item${location.pathname === `/${routeSelectState.selectedManifest}${ROUTES.route}` ? " xgs-menu__item--active" : ""}`}
            onClick={() => navigate(`/${routeSelectState.selectedManifest}${ROUTES.route}`)}
          >
            Route
          </div>
          <div
            className={`xgs-menu__item${location.pathname === `/${routeSelectState.selectedManifest}${ROUTES.map}` ? " xgs-menu__item--active" : ""}`}
            onClick={() => navigate(`/${routeSelectState.selectedManifest}${ROUTES.map}`)}
          >
            Map
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
