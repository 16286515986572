import React, { useEffect, useState } from "react";

export interface PolylineProps extends google.maps.PolylineOptions {
  polylineData: string;
}

const XGSPolyline: React.FC<PolylineProps> = ({ polylineData, ...props}) => {
  const [polyline, setPolyline] = useState<google.maps.Polyline>();

  useEffect(() => {
    if (!polyline) {
      setPolyline(new google.maps.Polyline({
        path: window.google.maps.geometry.encoding.decodePath(polylineData),
        strokeColor: "#000077",
        strokeOpacity: 1.0,
        strokeWeight: 5,
      }));
    }

    return () => {
      if (polyline) polyline.setMap(null);
    };
  }, [polyline, polylineData]);

  useEffect(() => {
    if (polyline) polyline.setOptions(props);
  }, [polyline, props]);

  return null;
};

export default XGSPolyline;
