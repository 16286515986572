import React, { useEffect, useState } from "react";
import { StopDetails } from "../../../app/data/stop-details/models";
import { PickupModel } from "../../../app/data/pickups/models";
import "./stop-details-sticky-header.scss";

export type StopDetailsStickyHeaderProps = {
  stopDetails: StopDetails | PickupModel;
  consigneeIdx: number;
  probillIdx: number;
};

export const StopDetailsStickyHeader: React.FC<
  StopDetailsStickyHeaderProps
> = ({ stopDetails, consigneeIdx, probillIdx }) => {
  const [show, setShow] = useState(false);
  const onScroll = () => {
    if (window.scrollY > 300) setShow(true);
    else setShow(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      className={
        `xgs-stop-details-sticky-header ` +
        `xgs-stop-details-sticky-header--${show ? "show" : "hide"}`
      }
    >
      {stopDetails && (
        <span>
          {`${
            stopDetails.consignees[consigneeIdx].name
          } : ${stopDetails.consignees[consigneeIdx].probills[
            probillIdx
          ].probill.toString()}  (${
            stopDetails.consignees[consigneeIdx].probills[
              probillIdx
            ].items
          })`}
        </span>
      )}
    </div>
  );
};
