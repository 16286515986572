import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import ExceptionsService from "../../app/data/exceptions/exceptionsService";
import { initialExceptionsState } from "./ExceptionsState";

const exceptionsService = ExceptionsService.getInstance();

export const exceptionsSlice = createSlice({
  name: "exceptions",
  initialState: initialExceptionsState,
  reducers: {
    resetExceptionsState: (state) => initialExceptionsState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestCreator = payload;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestError = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestError = payload.error;
      state.requestErrorCode = payload.code
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestError = null;
    }
  }
});

export const {
  resetExceptionsState,
  requestStarted,
  requestFailed,
  requestSucceed
} = exceptionsSlice.actions;

export const exceptionsSelector = (state: IState) => {
  return state.exceptions;
};

export const submitException = (
  fd: FormData,
  onSuccess: () => void,
  onOffline: () => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted("SUBMIT_EXCEPTION"));
  const response = await exceptionsService.submitException(fd);
  if (navigator.onLine) {
    if (response.ok()) {
      dispatch(requestSucceed());
      onSuccess();
    } else {
      dispatch(requestFailed({
        code: response.status,
        error: response.getError ? response.getError() : "Error"
      }));
    }
  } else {
    dispatch(requestSucceed());
    onOffline();
  }
};

const exceptionsReducer = exceptionsSlice.reducer;
export default exceptionsReducer;
