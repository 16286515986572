import dayjs from "dayjs";
import dateFormats from "../app/data/common/dateFormats";
import moment from "moment";

export {};

declare global {
  interface String {
    toUiDateFormat: () => string;
    toUiDateTimeFormat: () => string;
    toUiDateWeekFormat: () => string;
    toApiDateFormat: () => string;
    capitalize: () => string;
    formatPhone: () => string;
    toUiTimeFormat: () => string;
  }
}

export function initStringExtensions() {
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateFormat = function () {
    return dayjs(this.toString()).format(dateFormats.uiDate);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateTimeFormat = function () {
    return dayjs(this.toString()).format(dateFormats.uiDateTime);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiDateWeekFormat = function () {
    return dayjs(this.toString()).format(dateFormats.uiDateWeek);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toApiDateFormat = function () {
    return dayjs(this.toString()).format(dateFormats.apiDate);
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.toUiTimeFormat = function () {
    return moment(this.toString(), "HH:mm:ss").format(dateFormats.uiTime);
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.capitalize = function () {
    return this.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.formatPhone = function () {
    const cleanedPhone = ("" + this).replace(/\D/g, "");
    const match = cleanedPhone.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? "(" + match[1] + ") " + match[2] + "-" + match[3] : "";
  };
}
