import {
  RouteSummaryModel,
  NoRouteSummaryModel
} from "../../app/data/route-summary/models";
import { ApiBaseModel } from "../../app/data/common/models";

export default interface RouteSummaryState
  extends ApiBaseModel {
  route: RouteSummaryModel | NoRouteSummaryModel | null;
}

export const initialRouteSummaryState: RouteSummaryState = {
  route: null,
  requestStarted: false,
  requestSucceed: false,
  requestFailed: false,
  requestFailReason: null,
  requester: null
};
