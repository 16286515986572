import api, { ApiResponse } from "./../api";

class ExceptionsService {
  private static instance: ExceptionsService;
  private constructor() {};
  static getInstance(): ExceptionsService {
    if (!ExceptionsService.instance) {
      ExceptionsService.instance = new ExceptionsService();
    }
    return ExceptionsService.instance;
  };

  public submitException = async (
    data: FormData
  ): Promise<ApiResponse<any>> => {
    return await api.post("/drivers/exceptions", data, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  };
}

export default ExceptionsService;
