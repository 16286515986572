import api, { ApiResponse } from "../api";
import { RouteMapModel } from "./models";

class RouteMapService {
  private static instance: RouteMapService;
  private constructor() {}
  static getInstance(): RouteMapService {
    if (!RouteMapService.instance) {
      RouteMapService.instance = new RouteMapService();
    }
    return RouteMapService.instance;
  }

  public getRouteMapData = async (
    manifestNumber: number
  ): Promise<ApiResponse<RouteMapModel>> => {
    return await api.get(`/drivers/routes/${manifestNumber}/map`);
  };
}

export default RouteMapService;