import { ApiBaseModel } from "../../app/data/common/models";

export interface PickupShipmentState extends ApiBaseModel {
}

export const initialPickupShipmentState: PickupShipmentState = {
  requestStarted: false,
  requestFailed: false,
  requestSucceed: false,
  requestFailReason: null,
  requester: null
};
