import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../hooks/storeHooks";
import DropdownMenu, { DropdownMenuItem } from "../../../components/dropdown-menu/dropdownMenu";
import SlideInModal from "../../../components/slide-in-modal/slideInModal";
import { userSelector } from "../../../slices/user/userSlice";
import { commonSelector } from "../../../slices/common/commonSlice";
import { logout } from "../../../slices/user/userSlice";
import XGSIcon from "../../../components/icon/xgsIcon";
import XGSIcons from "../../../components/icon/xgsIcons";
import "./userMenu.scss";

const UserMenu: React.FC<{}> = (props) => {
  const dispatch = useAppDispatch();
  const userState = useSelector(userSelector);
  const commonState = useSelector(commonSelector);
  const [opened, setOpened] = useState<boolean>(false);
  const [showOfflineWarning, setShowOfflineWarning] = useState<boolean>(false);

  const menuItems: DropdownMenuItem[] = [
    {
      text: "Reload data",
      onClick: () => {
        if (commonState.offline || !navigator.onLine) {
          setShowOfflineWarning(true);
        } else {
          window.location.reload();
        }
        closeDropdown();
      },
      icon: XGSIcons.faSyncAlt
    },
    {
      text: "Log out",
      onClick: () => {
        setTimeout(() => {
          dispatch(logout());
        }, 500);
      },
      icon: XGSIcons.faSignOutAlt
    }
  ];

  const openDropdown = () => {
    setOpened(true);
  };

  const closeDropdown = () => {
    setOpened(false);
  };

  return (
    <div>
      <div className="xgs-user-menu" onClick={openDropdown}>
        <div className="xgs-user-menu__user xgs-user-menu__user--normal">
          <div className="xgs-user-menu__user__name">
            {userState.profile?.firstName} {userState.profile?.lastName}
          </div>
        </div>
        <div className="xgs-user-menu__user xgs-user-menu__user--short">
          {userState.profile?.firstName?.slice(0, 1)}
        </div>
        <span className="xgs-user-menu__arrow">
          <XGSIcon icon={opened ? XGSIcons.faChevronUp : XGSIcons.faChevronDown} />
        </span>
      </div>
      <DropdownMenu
        menuItems={menuItems}
        opened={opened}
        onClose={closeDropdown}
        className="xgs-user-menu__dropdown"
      />
      <SlideInModal
        title="Data reload is currently not possible."
        onClose={() => setShowOfflineWarning(false)}
        show={showOfflineWarning}
      >
        <div className="xgs-user-menu__reload-warning">
          <strong>You are offline now.</strong><br />
          To take advantage of the data update feature you need to be online.
        </div>
      </SlideInModal>
    </div>
  );
}

export default UserMenu;