import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { ManifestModel } from "../../app/data/route-select/models";
import { ManifestStatus } from "../../app/data/common/constants";
import RouteSelectService from "../../app/data/route-select/routeSelectService";
import { initialRouteSelectState } from "./RouteSelectState";

const routeSelectService = RouteSelectService.getInstance();

export const routeSelectSlice = createSlice({
  name: "routeSelect",
  initialState: initialRouteSelectState,
  reducers: {
    requestStarted: (state) => {
      state.requestStarted = true;
      state.requestSucceed = false;
      state.requestFailed = false;
      state.requestFailReason = null;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestSucceed = true;
      state.requestFailed = false;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestSucceed = false;
      state.requestFailed = true;
      state.requestFailReason = payload;
    },
    setManifests: (state, { payload }) => {
      state.manifests = payload;
    },
    updateManifestStatus: (state, { payload }) => {
      const manifests = state.manifests;
      if (manifests.length > 0) {
        const i = manifests.findIndex(manifest => manifest.manifestNumber === payload.manifestNumber);
        if (i !== -1) {
          if (payload.status === ManifestStatus.COMPLETED) {
            // remove from manifests if completed
            manifests.splice(i, 1);
          } else {
            manifests[i].status = payload.status;
          }
        }
        state.manifests = [...manifests];
      }
    },
    setActiveManifest: (state, { payload }) => {
      state.activeManifest = payload;
    },
    setSelectedManifest: (state, { payload }) => {
      state.selectedManifest = payload;
    },
    setRouteSelectCalledFromMenu: (state, { payload }) => {
      state.routeSelectCalledFromMenu = payload;
    }
  }
});

export const {
  requestStarted,
  requestSucceed,
  requestFailed,
  setManifests,
  updateManifestStatus,
  setActiveManifest,
  setSelectedManifest,
  setRouteSelectCalledFromMenu
} = routeSelectSlice.actions;

export const routeSelectSelector = (state: IState) => state.routeSelect;

export const getManifests = (
  onSuccess?: (manifests: ManifestModel[]) => void
): AppThunk => async (dispatch) => {
  dispatch(requestStarted());
  const response = await routeSelectService.getManifests();
  if (response.ok()) {
    dispatch(requestSucceed());
    dispatch(setManifests(response.data));
    onSuccess && onSuccess(response.data);
  } else {
    dispatch(requestFailed(response.getError && response.getError()));
  }
};

const routeSelectReducer = routeSelectSlice.reducer;
export default routeSelectReducer;