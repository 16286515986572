import React from "react";
import "./slideInModal.scss";
import XGSIcon from "../icon/xgsIcon";
import XGSIcons from "../icon/xgsIcons";
// @ts-ignore
import styles from "../../styles/variables.scss";

interface Props {
  children: any;
  className?: string;
  show: boolean;
  title?: string;
  onClose: () => void;
}

const SlideInModal: React.FC<Props> = ({
  className,
  children,
  show,
  title,
  onClose,
}) => {
  return (
    <>
      <div
        className={`xgs-slide-in-modal xgs-slide-in-modal--${
          show ? "opened" : "closed"
        } ${className? className : ""}`}
        onClick={(e) => {
          e.stopPropagation()
          onClose()
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
        }}
      ></div>
      <div
        className={`xgs-slide-in-modal__content xgs-slide-in-modal__content--${
          show ? "opened" : "closed"
        }`}
        onPointerDown={(e) => e.stopPropagation()}
      >
        <div className="xgs-slide-in-modal__header">
          <div>{title}</div>
          <XGSIcon
            cursor={"pointer"}
            icon={XGSIcons.faClose}
            onClick={onClose}
            color={styles.gray3}
            size="lg"
          />
        </div>
        <div className="xgs-slide-in-modal__body">{children}</div>
      </div>
    </>
  );
};

export default SlideInModal;