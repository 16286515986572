import api, { ApiResponse } from "../api";
import { PickupModel } from "./models";

class PickupsService {
  private static instance: PickupsService;
  private constructor() {}
  static getInstance(): PickupsService {
    if (!PickupsService.instance) {
      PickupsService.instance = new PickupsService();
    }
    return PickupsService.instance;
  }

  public getPickups = async (): Promise<ApiResponse<PickupModel[]>> => {
    return await api.get("/drivers/routes/pickup");
  };
}

export default PickupsService;