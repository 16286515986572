import api, { ApiResponse } from "../api";
import { SubmitPodRequest, SubmitPodResponse } from "./models";

class DeliverShipmentsService {
  private static instance: DeliverShipmentsService;
  private constructor() {};

  static getInstance(): DeliverShipmentsService {
    if (!DeliverShipmentsService.instance) {
      DeliverShipmentsService.instance = new DeliverShipmentsService();
    }
    return DeliverShipmentsService.instance;
  };

  public submitPod = async (
    manifestNumber: number,
    request: SubmitPodRequest
  ): Promise<ApiResponse<SubmitPodResponse>> => {
    return await api.post(`/drivers/routes/${manifestNumber}/delivery`, request);
  }

  public startDelivering = async (stopNumber: number): Promise<ApiResponse<any>> => {
    return await api.post(`/drivers/route/stop/${stopNumber}/start`)
  }
}

export default DeliverShipmentsService;
