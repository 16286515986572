import { useState, useEffect } from "react";

const useSectionIndicator = (parentId: string) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const parent = document.getElementById(parentId);
      if (!parent) return;

      const children = Array.from(parent.children) as HTMLElement[];
      let activeIdx: number | null = null;

      for (let idx = 0; idx < children.length; idx++) {
        const rect = children[idx].getBoundingClientRect();

        if (rect.bottom > 0) {
          activeIdx = idx;
          break;
        }
      }

      if (activeIdx !== null) {
        setCurrentSectionIndex(activeIdx);
      }
    };
    window.addEventListener("scroll", handleScroll)

    

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [parentId]);

  return currentSectionIndex;
};

export default useSectionIndicator;
